<template>
	<div class="list">
		<blue-title>商务合作</blue-title>
		<div class="scrollbox">
			<div class="hezuoitem" v-for="item in hezuo">
				<div class="company_name">{{ item.company_name }}</div>
				<div style="display:flex">
					<div class="addressname">地址：</div>
					<div class="address">{{ item.address }}</div>
				</div>
				<div class="phone">总机：{{ item.phone }}</div>
			</div>

		</div>

	</div>
</template>

<script>
import BlueTitle from './BlueTitle.vue';
export default {
	name: 'PcWebsiteHezuo',
	props: {
		hezuo: {
			type: Array,
			default: []
		}
	},
	components: {
		BlueTitle
	},

	data() {
		return {

		};
	},

	mounted() {
		console.log('hezuo', this.hezuo)

	},

	methods: {

	},
};
</script>

<style lang="scss" scoped>
.scrollbox {
	height: 715px;
	overflow: scroll;
}

.list {
	margin-left: 16px;
}

.hezuoitem {
	width: 326px;
	height: 110px;
	background: #F2F2F2;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	justify-content: space-around;
	align-items: flex-start;
}

.company_name {

	margin-left: 10px;
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #5B6980;
	line-height: 22px;
	text-align: left;
	overflow: hidden; //溢出内容隐藏
	text-overflow: ellipsis; //文本溢出部分用省略号表示
}

.addressname {
	width: 44px;
	margin-left: 10px;
	text-align: left;
	height: 40px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 20px;
}

.address {
	width: 250px;
	margin-left: 0px;
	text-align: left;
	height: 40px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 20px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden; //溢出内容隐藏
	text-overflow: ellipsis; //文本溢出部分用省略号表示
	display: -webkit-box; //特别显示模式
	-webkit-line-clamp: 2; //行数
	line-clamp: 2;
	-webkit-box-orient: vertical; //盒子中内容竖直排列
}

.phone {
	overflow: hidden; //溢出内容隐藏
	text-overflow: ellipsis; //文本溢出部分用省略号表示
	text-align: left;
	width: 306px;
	margin-left: 10px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 20px;
}
</style>